import React, { Component } from 'react';
import Liste from './Liste';
import Menu from './Menu';

class EmailListe extends Component {
    constructor(props) {
        super(props);
        this.setExportData = this.setExportData.bind(this)
    }
    
    state = {
        data: [],
     } 

    setExportData(data){
        if(JSON.stringify(data) === JSON.stringify(this.state.data) ) return;
        this.setState({data: data})
    }

    render() { 
        return (
            <>
                <div id='content' className='container'>
                    <Menu list={this.state.data}/>
                    <Liste export={this.setExportData}/>
                </div>
            </>
        );
    }
}
 
export default EmailListe;
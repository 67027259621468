import React, { Component } from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';

class InfoToast extends Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.timer = props.timer
    }
    style = {
        position: "absolute",
        right: "20px",
        bottom: "20px"
    }
    state = { 
        isOpen: false,
        message: ''
    } 
    
    toggle(data){
        console.log('toggle infoBox', data)
        this.setState({isOpen: !this.state.isOpen, message: data.data.message, info: data}, () => {
            this.timeout = setTimeout(() => {
                this.setState({isOpen: !this.state.isOpen})
            }, this.timer)
        });
    }

    render() {
        let headerClass = 'toast-ok';
        if(this.state.info && this.state.info.status !== 200) headerClass = 'toast-err';

        return (<>
            <Toast style={this.style} isOpen={this.state.isOpen}>
                <ToastHeader className={headerClass} toggle={this.toggle}>
                    Status: {this.state.info ? this.state.info.statusText : 'nicht OK'}
                </ToastHeader>
                <ToastBody>
                    {this.state.message}
                </ToastBody>
            </Toast>
        </>);
    }
}
 
export default InfoToast;
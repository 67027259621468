import React, { Component } from 'react';
import { Button, NavItem } from 'reactstrap';

class HeaderMenu extends Component {
    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this)
    }

    logout(){
        let auth = JSON.parse(window.localStorage.getItem("auth"));
        global.axiosHelper.delete(`/login/${auth.id}`) //delete authToken
        window.localStorage.removeItem('auth');
        window.location.reload();
    }

    render() { 
        return ( <> 
        <NavItem>
            {/* Loggout Button */}
            <Button outline color="light" size="sm" onClick={this.logout}>Logout</Button>
        </NavItem>
        </> );
    }
}
 
export default HeaderMenu;
import React, { Component } from 'react';
import FileExport from './FileExport';
import FileImporter from './FileImporter';
import NewFlagModal from './NewFlagModal';
import NewModal from './NewModal';

class Menu extends Component {
    state = { }
    
    render() { 
        return ( <>
        <div id="menu" className='row justify-content-center mt-4'>
            <div className='col-auto'>
                <NewModal withButton={true} />
                <NewFlagModal />
                <FileImporter />
                <FileExport search={this.props.list}/>
            </div>
        </div>

        </> );
    }
}
 
export default Menu;
import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import { niceSQLErrors } from '../Utils/NiceSQLErrors';

class ErrorNotice extends Component {
    
    state = { 
        isOpen: false,
        info: '',
    }

    onDismiss = () => this.setState({isOpen: false})

    toggle(data){
        console.log('ErrNot toggle', data)
        this.setState({isOpen: true, info: niceSQLErrors(data.response.data)});
    }

    render() { 
        return ( <>
            <Alert color='danger' isOpen={this.state.isOpen} toggle={this.onDismiss}>
                {this.state.info}
            </Alert>
        </> );
    }
}
 
export default ErrorNotice;
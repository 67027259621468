export const TableConfig = {
    "firmenname": {
        label: "Firmenname",
        table: "kontakte",
        canOrder: true,
    },
    "plz": {
        label: "PLZ",
        table: "kontakt",
        canOrder: true,
    },
    "email": {
        label: "E-Mail",
        table: "kontakte",
        canOrder: true,
        defaultOrder: 'asc',
    },
    "flags":{
        label: "Flags",
        table: "flags",
        canOrder: false,
        type: "select",
    }

}


export const FormKontaktFields = {
    "name": {
        label: 'Name',
    },
    "email":{
        label: "E-Mail"
    },
    "telefon": {
        label: "Telefon"
    }
}

export const FormFirmaFields = {
    "firmenname": {
        label: "Firmenname"
    },
    "straße": {
        label: "Straße"
    },
    "plz": {
        label: "PLZ",
        width: 6
    },
    "ort": {
        label: "Ort",
        width: 6
    },
    "bundesland": {
        label: "Bundesland / Kanton"
    },
}

import axios from 'axios';
import React, { Component } from 'react';
import { CSVLink } from "react-csv";
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

class FileExport extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this)
        // this.setCsvData = this.setCsvData.bind(this)
    }
    state = {
        isOpen: false,
        csvData: [],
        kontakte: {},
        search: {},
        loading: true,
    }

    componentDidMount(){
        this.setState({search: this.props.search, loading: true, kontakte: {}});
    }

    componentDidUpdate(props){
        if(this.props !== props){
            this.setState({search: this.props.search, loading: true, kontakte: {}});
        }
    }

    getKontakte(){
        this.setState({kontakte: null})
    
        global.axiosHelper.read(`/kontakte/exports`, this.state.search)
        .then(res => {
            this.setState({kontakte: res.data.kontakte, loading: false}, ()=> console.log(this.state.kontakte))
        })
    }

    setCsvData = (e, done) => {
        const kontakte = this.state.kontakte;
        kontakte.forEach(email => {
            delete email.id
            delete email.createdAt

            //flag behandlung
            let flags = email.flags;
            email.flags = flags.map(e => {
                return e.name;
            })

            //export notices
            let notices = email.notices
            let tmp = notices.map(notice => {
                return `${notice.createdAt}: ${notice.notice}`
            })
            email.notices = tmp.join('|');

        });
        console.log('after', kontakte);
        this.setState({csvData: kontakte}, this.toggle)
        done(true)
    }

    toggle(){
        this.setState({ isOpen: !this.state.isOpen }, () => {
            if(this.state.isOpen){
                this.getKontakte()
            }
        })
    }

    render() { 
        return (<>
            <Button onClick={this.toggle}>Export</Button>
            <Modal centered isOpen={this.state.isOpen} onClosed={this.onClose}>
                <ModalHeader toggle={this.toggle}>
                    Exportieren als CSV Datei
                </ModalHeader>
                <ModalBody className='text-center'>
                    <CSVLink asyncOnClick={true} 
                        onClick={this.setCsvData}
                        className='btn btn-secondary' data={this.state.csvData}
                        filename={new Date().toISOString().slice(0, 10)+"-export-liste.csv"}
                        separator={";"}
                    >
                        {this.state.loading ? 'bereite vor...' : 'Download'}
                    </CSVLink>
                </ModalBody>
            </Modal>
        </>)
    }
}
 
export default FileExport;
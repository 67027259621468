import React, { Component } from 'react';

class Loading extends Component {
    state = {  }
    render() { 
        return ( <>
            <div className='row jutify-content-center'>
                <div className='col-auto'>
                    <h4>Loading...</h4>
                </div>
            </div>
        </> );
    }
}
 
export default Loading;
import { Component, createRef } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import EditForm from './EditForm';
import ErrorNotice from './ErrorNotice';

class NewModal extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this)
        this.save = this.save.bind(this)
        this.onClose = this.onClose.bind(this)

        this.errorNotice = createRef()
        this.form = createRef()
    }
    state = { 
        isOpen: false,
        data: undefined,
        dialogOpen: false
    }

    componentDidMount(){
        this.setState({
            currentToast: global.toast.current,
        })
    }

    toggle(){
        this.setState({ isOpen: !this.state.isOpen })
    }

    save(){
        console.log('POST', this.form.current.state, this.props, this.state)
        global.axiosHelper.create(`/kontakte/`, this.form.current.state.data )
        .then(res => {
            if(this.form.current.state.flags.selected){
                this.form.current.state.flags.selected.map(flag => {
                    global.axiosHelper.create(`/kontakte_flags`, {
                        kontakte_id: res.data.result.lastInsertRowid,
                        flags_id: flag.value
                    }).catch(err => {
                        console.log(err)
                        this.errorNotice.current.toggle(err);
                    })
                })
            }
            this.state.currentToast.toggle(res);
            this.toggle();
        })
        .catch(err => {
            console.log(err)
            this.errorNotice.current.toggle(err);
        }).finally(()=> {
            global.emitter.emit('reloadList')
        })
    }

    onClose(){
        if(this.props.update) this.props.update(this.state);
        this.setState({data: undefined})
    }

    render() { 
        return (<>
            {this.props.withButton ? <Button onClick={this.toggle}>Neuer Eintrag</Button> : ''}
            <Modal fullscreen={true}  centered isOpen={this.state.isOpen} onClosed={this.onClose}>
                <ModalHeader toggle={this.toggle}>
                    Neuer Eintrag
                </ModalHeader>
                <ModalBody>
                    <EditForm new={true} ref={this.form}/>
                    <ErrorNotice ref={this.errorNotice}/>
                </ModalBody>
                <ModalFooter>
                <Button color="success" onClick={this.save} >
                    Speichern
                </Button>
                <Button onClick={this.toggle}>
                    Abbrechen
                </Button>
                </ModalFooter>
            </Modal>
        </>);
    }
}
 
export default NewModal;
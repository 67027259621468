import { Component, createRef } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ErrorNotice from './ErrorNotice';

class NewFlagModal extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this)
        this.save = this.save.bind(this)
        this.inputChangeHandler = this.inputChangeHandler.bind(this)
        this.getFlags = this.getFlags.bind(this)
        this.delete = this.delete.bind(this)
        this.edit = this.edit.bind(this)

        this.errorNotice = createRef()
    }
    state = { 
        isOpen: false,
        emailId: '',
        flagId: '',
        flag: {
            name: '',
            color: '#000000'
        },
        dialogOpen: false,
        flags: null,
        isEdit: false,
    }

    getFlags(){
        this.setState({falgs: null, flag: { name: '', color: '#000000'}, isEdit: false}, () => console.log(this.state)) //alles leeren
        global.axiosHelper.read(`/flags`)
          .then(res => {
            this.setState({ flags: res.data.flags });
          })
    }

    toggle(){
        this.setState({ isOpen: !this.state.isOpen }, () => {
            if(this.state.isOpen){
                this.getFlags()
            }else{
                global.emitter.emit('reloadList')
            }
        })
    }

    save(){
        this.errorNotice.current.onDismiss();

        console.log('POST/PUT', this.state.flag)
        if(this.state.isEdit){
            global.axiosHelper.update(`/flags/${this.state.flag.id}`, this.state.flag)
            .catch(err => {
                console.log(err)
                this.errorNotice.current.toggle(err);
            }).finally(()=> {
                this.getFlags()
            })

        }else{
            global.axiosHelper.create(`/flags`, this.state.flag)
            .catch(err => {
                console.log(err)
                this.errorNotice.current.toggle(err);
            }).finally(()=> {
                this.getFlags()
            })
        }
    }

    edit(e){
        console.log('edit', e.target.querySelector('span').dataset.id);
        let clicked = e.target.querySelector('span').dataset.id;
        let editFlag = this.state.flags.filter( flag => {
            return flag.id === parseInt(clicked);
        })
        this.setState({isEdit: true, flag: editFlag[0]})
    }

    delete(e){
        global.axiosHelper.delete(`/flags/${e.target.getAttribute('data-id')}`)
        .then(res => {
            // this.toggle();
        })
        .catch(err => {
            console.log(err)
            this.errorNotice.current.toggle(err);
        }).finally(()=> {
            this.getFlags()
        })

    }

    inputChangeHandler(e){
        this.setState({flag: {...this.state.flag, [e.target.name]: e.target.value}})
    }

    render() { 

        let flags = <p>Loading...</p>
        if(this.state.flags){
            flags = this.state.flags.map((flag) => {
                return (<span key={flag.id} className="badge" onClick={this.edit} style={{backgroundColor: flag.color, margin: '0 5px', position: 'relative'}} >
                    {flag.name}
                    <span data-id={flag.id} className='flagDelete' onClick={this.delete}>x</span>
                </span>)
            })
        }

        return (<>
            <Button onClick={this.toggle}>Flags</Button>
            <Modal size="lg" centered isOpen={this.state.isOpen} onClosed={() => this.setState({flag: { name: '', color: '#000000' }})}>
                <ModalHeader toggle={this.toggle}>
                    Flags
                </ModalHeader>
                <ModalBody>
                    <h5>Neues Flag:</h5>
                    <Form onSubmit={e => { e.preventDefault(); }}>
                        <Row className="g-3 align-items-end">
                            <Col>
                                <FormGroup>
                                    <Label for="name">Name</Label>
                                    <Input id="name" name="name" type="text" value={this.state.flag.name} onChange={this.inputChangeHandler}/>
                                </FormGroup>
                            </Col>
                            <Col md={"auto"}>
                                <FormGroup>
                                    <Label for="color">Farbe</Label>
                                    <Input id="color" name="color"  type="color" value={this.state.flag.color} onChange={this.inputChangeHandler}/>
                                </FormGroup>
                            </Col>
                            <Col md={"auto"}>
                                <Button className="mb-3" color="success" onClick={this.save} >
                                    {this.state.isEdit ? "Ändern": "Speichern"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <div id="flags" className="container-fluid mb-3">
                        <div className='row'>
                            <hr/>
                            <h5>Übersicht</h5>
                            <div className='col' style={{fontSize: '1.2rem'}}>
                                {flags}
                            </div>
                        </div>
                    </div>
                    <ErrorNotice ref={this.errorNotice}/>
                </ModalBody>
                <ModalFooter>
                <Button onClick={this.toggle}>
                    Schließen
                </Button>
                </ModalFooter>
            </Modal>
        </>);
    }
}
 
export default NewFlagModal;
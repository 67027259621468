const http = require("axios").default;

module.exports = class AxiosHelper {
  constructor() {
    // this.authTimer = setInterval(async () => {
    //     await this.ensure_auth();
    //     console.log('update auth token -> ' + new Date().toDateString());
    // }, 60 * 60 * 1000);
    this.auth = {};
    this.retries = {};
    this.api_url = process.env.REACT_APP_API_URL;
    this.http = http;
    this.config = {
      headers:{
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': ""
      }
    }

  }

  // auth get token for service requests
  // ensure_auth() {
  //     return new Promise((done, failed) => {
  //         const form = new URLSearchParams();
  //         form.append('grant_type', 'password');
  //         form.append('username', 'support@sobek-innovations.de');
  //         form.append('password', 's1pp2rt.101078!');
  //         form.append('client_id', 'pages2go_web_client_production');
  //         form.append('client_secret', '123');
  //         http.post(this.auth_server, form, {
  //             headers: {
  //                 'Content-Type': 'application/x-www-form-urlencoded'
  //             }
  //         }).then((res) => {

  //             if (res.data && res.status === 200) {
  //                 this.token = res.data.access_token;
  //                 done(this.token);
  //             } else {
  //                 console.error(res.status, res.data);
  //                 failed("wrong status code " + res.status + " for " + this.auth_server);
  //             }
  //         }).catch((err) => {
  //             console.log('product request error', err);
  //         });

  //     })
  //   }

  // handleCatch() {

  // }
  async raw_request(resource, method, data) {
    this.auth = JSON.parse(window.localStorage.getItem('auth'))

    return new Promise((done, failed) => {
      var headers = {
        ...this.config.headers,
        Authorization: this.auth !== null ? this.auth.token : "",
      };

      var handle = (res) => {
        // console.log('handle', res);
        let data = {
          data: res.data,
          status: res.status,
          statusText: res.statusText
        };
        if (res.status === 200) {
          done(data);
        } else {
          failed(res);
        }
      };

      var timeout = 8000;

      if (data && (method === "post" || method === "put")) {
        if (typeof data === "object") data = JSON.stringify(data);
        http[method](this.api_url + resource, data, { headers, timeout })
          .then((res) => {
            var retryKey = method + " - " + this.api_url + resource;
            delete this.retries[retryKey];
            handle(res);
          })
          .catch((e) => {
            // console.error("Error put || post", {
            //   url: this.api_url + resource,
            //   method,
            //   code: e.code,
            //   msg: e.message,
            //   status: e.status
            // }, e);
            failed({
              url: this.api_url,
              method,
              status: e.status,
              response: e.response,
            });
          });
      } else {
        http[method](this.api_url + resource, { headers, timeout , params: data})
          .then((res) => {
            var retryKey = method + " - " + this.api_url + resource;
            delete this.retries[retryKey];
            handle(res);
          })
          .catch((e) => {
            var retryKey = method + " - " + this.api_url + resource;
            if (!this.retries[retryKey]) this.retries[retryKey] = 0;
            if (
              e.message.indexOf("timeout of") !== -1 &&
              this.retries[retryKey]++ < 3
            ) {
              this.raw_request(resource, method, data).then(done).catch(failed);
            } else {
              // console.error('crreate || read', {
              //   url: this.api_url + resource,
              //   method,
              //   code: e.code,
              //   msg: e.message,
              //   status: e.status
              // }, e);
              failed({
                url: this.api_url + resource,
                method,
                code: e.code,
                msg: e.message,
                status: e.status,
                res: e.response
              });
            }
          });
      }
    });
  }

  async create(path, data) {
    var res = await this.raw_request(path, "post", data);
    console.log('creatre', res);
    return res;
  }

  async read(path, query) {
    var res = await this.raw_request(path, "get", query);
    return res;
  }

  async update(path, data) {
    var res = await this.raw_request(path, "put", data);
    return res;
  }

  async delete(path, data) {
    var res = await this.raw_request(path, "delete", data);
    return res;
  }
};

import axios from 'axios';
import { Component, createRef } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { assignFlags } from '../Utils/FlagHelper';
import EditForm from './EditForm';
import ErrorNotice from './ErrorNotice';

class EditModal extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this)
        this.onOpened = this.onOpened.bind(this)
        this.edit = this.edit.bind(this)
        this.delete = this.delete.bind(this)
        this.inputChangeHandler = this.inputChangeHandler.bind(this)
        this.toggleSure = this.toggleSure.bind(this)

        this.errorNotice = createRef();
        this.form = createRef();
    }

    state = { 
        isOpen: false,
        kontaktId: '',
        kontakt: undefined,
        dialogOpen: false
    }

    componentDidMount(){
        this.setState({
            currentToast: global.toast.current,
        })
    }

    toggle(){
        this.setState({ isOpen: !this.state.isOpen })
    }

    toggleSure(){
        this.setState({dialogOpen: !this.state.dialogOpen})
    }

    edit(){
        console.log('edit', this.form.current.state.data)
        global.axiosHelper.update(`/kontakte/${this.state.kontaktId}`, this.form.current.state.data)
        .then( async res => {
            //wenn ok auch Flags anpassen
            await assignFlags(this.state.kontakt.id, this.form.current.state.flags.selected, this.state.kontakt.flags)
            .catch(err => {throw err})

            //notice hinzufuegen
            let note = "Kontakt gespeichert.";
            await global.axiosHelper.create(`/notices/`, {kontakte_id: this.state.kontakt.id, notice: note})
            .catch(err => console.log(err))

            this.setState({kontakt: this.form.current.data}, () => {
                global.emitter.emit('reloadList')
                this.toggle();
            })
        })
        .catch(err => {
            console.log(err)
            this.errorNotice.current.toggle(err);
        })
    }

    delete(){
        global.axiosHelper.delete(`/kontakte/${this.state.kontaktId}`)
            .then(res => {
                this.toggleSure()
                this.toggle()
                this.state.currentToast.toggle(res.data);
            })
            .catch(err => {
                console.log(err)
                this.errorNotice.current.toggle(err);
            }).finally(()=> {
                global.emitter.emit('reloadList')
            })
    }

    inputChangeHandler(e){
        if(this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            // console.log('change', e.target.name, e.target.value)
            this.setState({email: {...this.state.kontakt, [e.target.name]: e.target.value}}, () => console.log(this.state.kontakt))
        }, 300);
    }

    async onOpened(){
        await global.axiosHelper.read(`/kontakte/${this.state.kontaktId}`)
        .then(res => {
          this.setState({ kontakt: res.data.kontakte[0] });
        })
    }

    render() { 
        let form = <p>loading...</p>

        if(this.state.kontakt){
            form = <EditForm edit={true} ref={this.form} data={this.state.kontakt} change={this.inputChangeHandler}/>
        }

        return (<>
            <Modal fullscreen={true} centered isOpen={this.state.isOpen} onOpened={this.onOpened} onClosed={() => this.setState({kontakt: undefined, kontaktId:''})}>
                <ModalHeader toggle={this.toggle}>
                    Bearbeiten
                </ModalHeader>
                <ModalBody>
                    {form}
                    <ErrorNotice ref={this.errorNotice}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={this.toggleSure} >
                        Löschen
                    </Button>
                    <Button onClick={this.edit}>
                        Ändern
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal centered isOpen={this.state.dialogOpen}>
                <ModalBody>
                    <p style={{fontSize: 20}}>Sind Sie sicher das Sie den Eintrag unwiederruflich löschen möchten?</p>
                </ModalBody>
                <ModalFooter style={{justifyContent: "space-between"}}>
                <Button color="danger" onClick={this.delete} >
                    Löschen
                </Button>
                <Button onClick={this.toggleSure}>
                    Abbrechen
                </Button>
                </ModalFooter>
            </Modal>
        </>);
    }
}
 
export default EditModal;
import React, { Component } from 'react';

class Pagination extends Component {
    state = { 
        maxPages: 3,
        currentPage: 1,
        limit: 25,
        maxPagination: 16,
        count: 100,
    }

    componentDidMount(){
        this.setState(this.props.pagination)
    }

    componentDidUpdate(props){
        // console.log('pegi update', props.pagination, this.props.pagination);
        if(props !== this.props) this.setState(props.pagination)
    }

    createPagination(){
        let pageLinks = []
        let plusPages = Math.ceil((parseInt(this.state.currentPage) + this.state.maxPagination/2))
        let minusPages = Math.ceil(parseInt(this.state.currentPage) - (this.state.maxPagination/2))
        let idxStart = 2;

        //wenn wenig zeig einfach die an 
        if(this.state.maxPages <= this.state.maxPagination){
            for (let i = 1; i <= this.state.maxPages; i++) {
                pageLinks.push(i)
            }
            return pageLinks
        }
       

        //zeige immer die erste Seite
        pageLinks.push(1)

        if(minusPages < 0){
            plusPages += minusPages*-1
        }
        if( minusPages > 1){
            idxStart = minusPages
        }
        if(plusPages > this.state.maxPages){
            minusPages = (this.state.maxPages - this.state.maxPagination)
            plusPages = this.state.maxPages -1
        }

        for (let i = idxStart; i <= plusPages; i++) {
            pageLinks.push(i)
        }

        //zeige Letzte immer
        pageLinks.push(this.state.maxPages)

        return pageLinks
    }

    render() { 
        return ( <>
        <div id="pagination" className='row justify-content-center align-items-center'>
            <div className='col-auto'>
                <span>Anzeige: {this.state.limit} / {this.state.count}</span>
            </div>
            <div className='col-auto'>
                <ul className="pagination">
                    <li className={this.state.currentPage === 1 ? 'd-none': 'page-item'}>
                        <span className="page-link" onClick={() => this.props.changePage(this.state.currentPage-1)} aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </span>
                    </li>
                    { this.createPagination().map((e, idx) => {
                        return <li key={idx} className={parseInt(this.state.currentPage) === e ? 'page-item active' : 'page-item'}><span className="page-link" onClick={() => this.props.changePage(e)}>{e}</span></li>
                        // return <li className='page-item'><a className='page-link'>{e} - {idx}</a></li>
                    }) }
                    <li className={this.state.currentPage === this.state.maxPages ? 'd-none': 'page-item'}>
                        <span className="page-link" onClick={() => this.props.changePage(this.state.currentPage+1)} aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        </> );
    }
}
 
export default Pagination;
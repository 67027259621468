import axios from 'axios';
import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { assignFlags, getFlagIdByName } from '../Utils/FlagHelper';
import { niceSQLErrors } from '../Utils/NiceSQLErrors';

class FileImporter extends Component {
    constructor(props) {
        super(props);
        this.fileReader = new FileReader();
        this.count = 0
        this.defaultState = 

        //handlers
        this.toggleImportDialogOpen = this.toggleImportDialogOpen.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleOnSubmit = this.handleOnSubmit.bind(this)
        this.showErrorsOnly = this.showErrorsOnly.bind(this)
    }

    state = {
        importDialogOpen: false,
        file: null,
        csvHeaderKeys: [],
        csvData: [],
        showVorschau: false,
        hideImportButton: true,
        showErrorsOnly: false,
    }
    
    toggleImportDialogOpen(exit = false){
        this.setState({
            importDialogOpen: !this.state.importDialogOpen,
            file: null,
            csvHeaderKeys: [],
            csvData: [],
            showVorschau: false,
            hideImportButton: true, 
            showErrorsOnly: false,
        })
        this.count = 0;
        if(exit) global.emitter.emit('reloadList');
    }

    async handleOnSubmit(e){
        e.preventDefault();
        if(!this.state.hideImportButton && this.state.csvData){
            //import starten!
            this.handleImport();
        }
    };

    handleOnChange(e){
        this.setState({file: e.target.files[0]}, async () => {
            //File vorbereiten und Vorschau anzeigen
            if(this.state.file && this.state.hideImportButton) {
                let csvData = await this.readFile(this.state.file)
                this.csvFileToArray(csvData);
            }
        });
    }

    readFile(file){
        return new Promise((resolve, reject) => {
            this.fileReader.onerror = reject;
            this.fileReader.onload = event => resolve(event.target.result);
            this.fileReader.readAsText(file) //liest und ruft die .onload welches sich datei Merkt!
        });
    }


    csvFileToArray(string){
        let csvHeader = string.slice(0, string.indexOf("\n")).split(";");
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
        csvHeader[csvHeader.length] = 'Status';
    
        const array = csvRows.map(i => {
          const values = i.split(";");
          const obj = csvHeader.reduce((object, header, index) => {
            header = header.replaceAll('"', '')
            let val = values[index] ? values[index].replaceAll('"', '') : ''
            object[header] = val;
            return object;
          }, {});
          return obj;
        });
    
        console.log('array', array)

        this.setState({
            csvData: array, 
            csvHeaderKeys: Object.keys(Object.assign({}, ...array)),
            showVorschau: true,
        },()=> {
            this.setState({hideImportButton: false})
        });
    }

    async handleImport(){
        //starte Import zur Datenbank
        let array = this.state.csvData.map( (element) => {
            return Object.fromEntries(Object.entries(element).map(([key, value]) => {
                    return [key.toLowerCase(), value];
                }));
        });
        console.log('Start Import Liste')


        for (const set of array) {
            //Kontakte Importieren
            await this.dataToSchema(set, global.schemas.kontakte).then( async new_kontakt => {
                //Flags
                if(set.flags !== undefined && set.flags !== ""){
                    let flags = set.flags.split(',');
                    for (const flag of flags) {
                        getFlagIdByName(flag).then(async flag => {
                            let new_flag = {flags_id: flag.id, kontakte_id: new_kontakt.data.result.lastInsertRowid}
                            await this.import(new_flag, "/kontakte_flags")
                             .catch(err => console.log(err))
                        })
                    }
                }
                if(set.notices !== undefined && set.notices !== "") {
                    console.log("Import Notices to Kontakt");
                    let notices = set.notices.split('|');
                    for(const notic of notices){
                        //notice hinzufuegen
                        let old_timestamp = notic.substr(0, 19);
                        let note = notic.substr(21);
                        await global.axiosHelper.create(`/notices/`, {kontakte_id:  new_kontakt.data.result.lastInsertRowid, notice: note, createdAt: old_timestamp})
                        .catch(err => console.log(err))
                    }
                }
            })
        }
    }

    async dataToSchema(dataSet, schema){
        // console.log('Data', dataSet)
        // console.log('Schmea', schema);
        return new Promise( (done, failed) => {
            let data = schema.reduce((a, v) => {
                return {...a, [v]: ''} 
            }, {});
            delete data.id
            delete data.createdAt
            for (const [key, value] of Object.entries(dataSet)) {
                if(schema.includes(key)) data = { ...data, [key]: value } 
            }
            done(this.import(data))
        })
    }

    async import(dataSet, table = '/kontakte'){
        console.log('post:', dataSet, table);
        let ret = "";
        return new Promise(async (done, fail) => {
            let s = 'OK';
            let trClass = 'sql-ok';
            await global.axiosHelper.create(table, dataSet)
            .then(res => {
                s = niceSQLErrors(res.data.message)
                if(table === '/kontakte'){ //bei import neuer Kontakte auch die Import labels setzten, gern doppelt!
                    console.log('res', res);
                    ret = res
                    assignFlags(res.data.result.lastInsertRowid, [{label: 'neu', value: 1}, {label: 'importiert', value: 2}])
                }
            }).catch(err => {
                s = niceSQLErrors(err.response.data)
                trClass = 'sql-error'
            }).finally((res) => {
                if(table === '/kontakte'){
                    let newData = [...this.state.csvData]
                    newData[this.count].Status= s
                    newData[this.count].statuscode = trClass;
                    
                    this.count++
                    this.setState({csvData: newData}, () => {
                        // console.log('State', this.state.csvData, s, this.count)
                        console.log('fin', ret, res);
                        done(ret)
                    })
                }
            })
        })
    }

    showErrorsOnly(){
        this.setState({showErrorsOnly: true});
    }

    render() { 
        let tableBody = this.state.csvData.map((item, i) =>{
            let trClass = '';
            let tableData = Object.keys(item).map((key) =>{
                let val = item[key]
                // console.log('test', item, val)
                if(key === 'statuscode'){ 
                    if(this.state.showErrorsOnly && val === 'sql-ok'){
                        trClass = 'd-none ';
                    }                    
                    trClass += val; 
                    return ''   
                }
                return <td key={val}>{val}</td>
            });

            return <tr key={i} className={trClass}>{tableData}</tr>
        })

        return ( <>
            <Button onClick={this.toggleImportDialogOpen} >Import CSV</Button>
            <Modal fullscreen centered isOpen={this.state.importDialogOpen}>
                <ModalHeader toggle={this.toggleImportDialogOpen}>CSV IMPORT</ModalHeader>
                <ModalBody>
                    <form>
                        <input 
                        type={"file"}
                        id={"csvFileInput"}
                        accept={".csv"}
                        onChange={this.handleOnChange}
                        />
                    </form>
                    <div id="csvVorschau" className="container-fluid" style={{visibility: this.state.showVorschau ? 'visible' : 'hidden'}}>
                        <div className='row'>
                            <hr/>
                            <div className='col'>
                                <p>Importierte Datensätze: {this.count} von {this.state.csvData.length} <span className='link' onClick={this.showErrorsOnly}>Zeige nur die Fehler</span></p>
                                <table>
                                    <thead>
                                    <tr key={"header"}>
                                        {this.state.csvHeaderKeys.map((key, i) => (
                                        <th key={i}>{key}</th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {tableBody}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{justifyContent: "space-between"}}>
                    <Button color="primary" onClick={this.handleOnSubmit} disabled={this.state.hideImportButton}>
                        Import starten
                    </Button>
                    <Button style={{float: 'right'}} onClick={this.toggleImportDialogOpen}>
                        Abbrechen
                    </Button>
                </ModalFooter>
                
            </Modal>
        
        
        </> );
    }
}
 
export default FileImporter;
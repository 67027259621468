export function niceSQLErrors(key) {
    let string = '';
    switch (key) {
        case 'UNIQUE constraint failed: kontakte.email':
            string = 'E-Mail Adresse ist schon vorhanden.';
            break;
    
        case 'UNIQUE constraint failed: flags.name':
            string = 'Flag Name ist schon vorhanden.';
            break;

        case 'FOREIGN KEY constraint failed':
            string = 'Flag ist noch Zugeordnet, bitte zuerst entfernen';
            break;
    
        default:
            // console.log('Error Nachricht nicht bekannt: ', key)
            string = key
            break;
    }

    return string;
}
import axios from 'axios';
import React, { Component, createRef } from 'react';
import Select from 'react-select';
import {  Form, FormGroup, Input, Label } from 'reactstrap';

class FilterForm extends Component {
    constructor(props) {
        super(props);
        this.placeholder = props.placeholder;
        this.timeout = 0;
        this.element = createRef()

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.escFunction = this.escFunction.bind(this);
        this.onChange = this.onChange.bind(this);

        this.state = {
            table: props.table,
            isOpen: false,
            selectoptions: [
                { value: 'chocolate', label: 'Chocolate' },
                { value: 'strawberry', label: 'Strawberry' },
                { value: 'vanilla', label: 'Vanilla' }
              ]
        }
    }


    componentDidMount(){
      document.addEventListener("search", this.escFunction, false);

      //select brauchen wir auch die Daten
      if(this.props.type === "select" && this.state.table !== undefined){
        this.getTableValues(this.state.table)
      }
    }
    componentWillUnmount(){
      document.removeEventListener("search", this.escFunction, false);
    }

    escFunction(event){
        event.target.blur();
    }

    onChange(option, actionMeta){
        console.log(option, actionMeta);
        let s = ""
        let a = option.map( (opt) => {
            return opt.value
        })
        if(a.length > 0){
            s = a.reduce((accumulator, currentValue) => `${accumulator},${currentValue}`)
        }
        console.log(s);
        this.props.handler(actionMeta.name, s)
     }

    inputChangeHandler(e){
        if(this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.props.handler(e.target.name, e.target.value)
        }, 300);
    }

    getTableValues(table){
        global.axiosHelper.read(`/${table}`)
        .then(res => {
            if(table === 'flags'){
                this.setState( (state) => {
                    state.selectoptions = res.data.flags.map( (flag) => {
                        // console.log(flag);
                        return { value: flag.name, label: flag.name, color: flag.color }
                    })
                })
            }
        })
    }

    render() { 

        let orderByElement = ''
        // console.log(this.props.order)
        if(this.props.order){
            orderByElement = <span className='sort-by'>
                <span data-column={this.placeholder} data-order='asc' onClick={this.props.order} className={`sort-by-asc ${this.props.isDefaultOrder === 'asc' ? "active" : ""}`}/>
                <span data-column={this.placeholder} data-order='desc' onClick={this.props.order} className={`sort-by-desc ${this.props.isDefaultOrder === 'desc' ? "active" : ""}`}/>
            </span>;
        }

        let form = <>
            <Form inline onSubmit={e => { e.preventDefault(); }}>
                <FormGroup floating>
                    <Input
                        id={this.placeholder}
                        name={this.placeholder}
                        placeholder={this.placeholder}
                        type="search"
                        onChange={this.inputChangeHandler}
                    />
                    <Label for={this.placeholder}>{this.placeholder}</Label>
                </FormGroup>
            </Form>
        </>
        if(this.props.type === "select"){
            form = <>
                {/* <Form inline onSubmit={e => { e.preventDefault(); }}> */}
                        <Select
                            styles={{
                                container: (baseStyles) => ({
                                    ...baseStyles,
                                    paddingRight: "0.5rem",
                                    marginLeft: "-0.5rem",
                                    minWidth: "250px"
                                }),
                                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                    return {
                                      ...styles,
                                      backgroundColor: isDisabled ? undefined : isSelected ? data.color  
                                        : isFocused
                                        ? data.color
                                        : "white",
                                      color: isDisabled
                                        ? '#ccc'
                                        : isFocused
                                        ? '#fff'
                                        : 'black',
                                      cursor: isDisabled ? 'not-allowed' : 'default',
                                    };
                                  },
                                multiValue: (styles, { data }) => {
                                    return {
                                      ...styles,
                                      backgroundColor: data.color,
                                      color: "#fff",
                                    };
                                },
                                multiValueLabel: (styles, { data }) => ({
                                    ...styles,
                                    color: "#fff",
                                }),
                            }}
                            placeholder={"Select "+ this.placeholder}
                            isMulti
                            name="flags"
                            options={this.state.selectoptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this.onChange}
                        />
                {/* </Form> */}
            </>
        }

        return ( <>
            {orderByElement}
            { form }
        </> );
    }
}
 
export default FilterForm;

import React, { Component, createRef } from 'react';
import Select from 'react-select';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { FormFirmaFields, FormKontaktFields } from '../config/TableConfig';
import Notices from './Notices';

class EditForm extends Component {
    constructor(params) {
        super(params)

        this.modal = createRef();
        this.notices = createRef()

        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.inputChangeHandler = this.inputChangeHandler.bind(this)
        this.getSelectOptions = this.getSelectOptions.bind(this)
    }

    state = { 
        data: undefined
    } 

    componentDidMount(){
        // console.log('mount edit', this.props, this.state.data, global.schemas)
        if(this.props.new){
            this.setState(state => {
                state.data = global.schemas.kontakte.reduce(function(acc, cur, i) {
                    acc[cur] = '';
                    return acc;
                }, {});

                state.flags = {
                    selected: {},
                    opts: []
                }
                return state
            })
            this.getSelectOptions('flags');
        }
        
        if(this.props.data){ //edit
            // console.log('edit', this.props.data)
            this.setState(state => {
                state = {
                    data: {
                        ...this.props.data, 
                    }, 
                    flags: {selected: [], opts: []}, 
                }
                return state;
            }, () => {
                let selectedFlags = [] 
                if(this.state.data.flags && this.state.data.flags.length !== 0){
                    this.state.data.flags.forEach(element => {
                        selectedFlags.push({value: element.id, label: element.name});
                    });
                }
                this.getSelectOptions('flags', selectedFlags);
            })
        }

    }

    getSelectOptions(resource = 'flags', selected = ''){
        let options = []
        global.axiosHelper.read('/'+resource).then((res) => {
            console.log('getSelectOptions:', res)
            res.data[resource].forEach(element => {
                    options.push({value: element.id, label: element.name});
            })
            this.setState({[resource]: {selected: selected, opts: options}})

        }).catch(err => console.log('err getSelect Opt', err))
        
    }

    handleSelectChange(element, target){
        // console.log(element, target, this.state)
        this.setState({[target.name]: {...this.state[target.name], selected: element}})
    }

    inputChangeHandler(e){
        this.setState({data: {...this.state.data, [e.target.name]: e.target.value}})
    }

    render() { 
        let form = <p>Loading...</p>
        if(this.state.data){
            form = <>
            <Form id='form'>
                <Row>
                    <Col md={6}>
                        <h5 className='formTitle'>Kontakt</h5>
                        {Object.keys(this.state.data).map((el, i) => {
                            if(el in FormKontaktFields){
                                return (
                                    <Col key={el+'-'+i} md={12}>
                                        <FormGroup key={el+'-'+i}>
                                            <Label for={el}>{FormKontaktFields[el].label}</Label>
                                            <Input id={el} name={el} type="text" defaultValue={this.state.data[el]} onChange={this.inputChangeHandler}/>
                                        </FormGroup>
                                    </Col>
                                )
                            } 
                            return ''
                        })}
                    </Col>
                    <Col md={6}>
                        <h5 className='formTitle'>Firma</h5>
                        <Row>
                            {Object.keys(this.state.data).map((el, i) => {
                                if(el in FormFirmaFields){
                                    let width = FormFirmaFields[el].width ? FormFirmaFields[el].width : 12
                                    return (
                                        <Col key={el+'-'+i} md={width}>
                                            <FormGroup key={el+'-'+i}>
                                                <Label for={el}>{FormFirmaFields[el].label}</Label>
                                                <Input id={el} name={el} type="text" defaultValue={this.state.data[el]} onChange={this.inputChangeHandler}/>
                                            </FormGroup>
                                        </Col>
                                    )
                                } return ''
                            })} 
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <h5 className='formTitle'>Flags</h5>
                            <Select
                                onChange={this.handleSelectChange}
                                value={this.state.flags.selected}
                                isMulti
                                name="flags"
                                options={this.state.flags.opts}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
            <Notices ref={this.notices} kontaktId={this.state.data.id}/>
        </>}
        
        return (<>
            {form}
        </>);
    }
}
 
export default EditForm;
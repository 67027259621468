import React, { Component } from "react";
import Header from "../Header";
import { Navigate } from "react-router-dom"

export default class Login extends Component {
    
  constructor(props){
    super(props);
    this.state = {
      email: "",
      pwd: "",
      alertMessage: "",
      redirect: false
    };

  }
  async login(){
    let data = {
      'email': this.state.email,
      'password'  : this.state.pwd
    }
    await global.axiosHelper.create('/login', data).then(res => {
      console.log('resposne', res);
      window.localStorage.setItem('auth', JSON.stringify(res.data));
      this.setState({redirect: true})

    }).catch(err => {
      // console.log('err', err);
      this.setState({
        alertMessage: 
        <div className="callout callout-warning">
          <h6>Fehler: { err.response.data.message }</h6>
        </div> 
      }); 
    })
  }

  render (){
    return(<>
        {this.state.redirect && <Navigate to="/"/>}
        <Header />
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-auto">
                    <div className="login-box" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '5rem' }}>
                        <div className="card">
                        <div className="card-body login-card-body">
                            <form action="" method="post">
                            <div className="input-group mb-3">
                                <input type="email"
                                className="form-control"
                                placeholder="Email"
                                value={this.state.email}
                                autoFocus
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.login()
                                    }
                                }}
                                onChange={(e) => this.setState({email: e.target.value})} />
                            </div>
                            <div className="input-group mb-3">
                                <input type="password" 
                                className="form-control" 
                                placeholder="Password" 
                                value={this.state.pwd} 
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                    this.login()
                                    }
                                }}
                                onChange={(e) => this.setState({pwd: e.target.value}) } 
                                />
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-4">
                                <button type="button" className="btn btn-primary btn-block" onClick={() => this.login()}>Login</button>
                                </div>
                            </div>
                            <div id="alert-message" className="mt-4">
                                { this.state.alertMessage }
                            </div>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
  }
}
import React, { Component, createRef } from 'react';
import { Table } from 'reactstrap';
import EditModal from './EditModal';
import FilterForm from './FilterForm';
import { TableConfig } from '../config/TableConfig';
import Pagination from './Pagination';

class Liste extends Component {
    constructor(props) {
        super(props);
        this.modal = createRef();

        this.rowClickHandler = this.rowClickHandler.bind(this)
        this.getKontakte = this.getKontakte.bind(this)
        this.setSearchParams = this.setSearchParams.bind(this);
        this.handlOrderBy = this.handlOrderBy.bind(this);
        this.changePage = this.changePage.bind(this)

    }

    state = { 
        boxStyle: {
            borderTop: "1px solid grey",
            marginTop: "2em",
            padding: "15px",
        },
        kontakte: null,
        search: {
            bundesland: '',
            email: '', 
            kategorie: '',
            flags: '',
            orderBy: 'ORDER BY email asc', 
        },
        pagination: {
            currentPage: 1,
            limit: 50,
            maxPages: 3,
            count: 100,
        }
    }

    componentDidUpdate(props){
        if(props !== this.props) {
            this.getKontakte();
        }
    }

    componentDidMount() {
        this.getKontakte();
        global.emitter.on('reloadList', () => this.getKontakte());
    }
    componentWillUnmount(){
        global.emitter.removeListener('reloadList', () => console.log('reloadList event removed'));
    }
    
    getKontakte(){
        this.setState({kontakte: null}, () => {
            let params = Object.assign(this.state.search, this.state.pagination)
            global.axiosHelper.read(`/kontakte`, params)
              .then(res => {
                const kontakte = res.data.kontakte;
                let pagination = res.data.pagination //pagination auf 1 wenn wir zu weit drin sind
                if(pagination.currentPage >= pagination.maxPages){
                    pagination.currentPage = 1;
                }

                this.setState({ kontakte: kontakte, pagination: pagination}, () => this.props.export(this.state.search));
              })
        })
    }

    rowClickHandler(e){
        this.modal.current.setState({kontaktId: e.target.closest('tr').id}) 
        this.modal.current.toggle();
    }

    setSearchParams(columm, value){
        if(columm === 'E-Mail') columm = 'email';
        this.setState({search: {...this.state.search, [columm.toLowerCase()]: value}} , () => global.emitter.emit('reloadList'))
    }

    handlOrderBy(e){
        let column = e.target.dataset.column
        let order = e.target.dataset.order
        document.querySelectorAll('.sort-by .active').forEach(node => node.classList.remove('active') );
        e.target.classList.toggle("active");

        if(column === 'E-Mail') column = 'email';
        console.log('order', column, order)

        this.setState({search: {...this.state.search, orderBy: `ORDER BY ${column.toLowerCase()} ${order}`}}, () => this.getKontakte())
    }

    changePage(e){
        this.setState({pagination: {...this.state.pagination, currentPage: e}}, () => {
            this.getKontakte()
        })
    }


    render() { 

        let tableRows = <tr><td key="loading" className="text-center" colSpan={4}><h3>Loading...</h3></td></tr>

        if(this.state.kontakte){
            if(this.state.kontakte.length !== 0){
                tableRows = this.state.kontakte.map((kontakt, index) => {
                    //flags
                    let flags = ''
                    if(kontakt.flags && kontakt.flags.length > 0){
                        flags = kontakt.flags.map((flag) => {
                            return ( 
                                <span key={kontakt.id+'-'+flag.id} className="badge" style={{backgroundColor: flag.color, margin: '0 5px', position: 'relative'}} >
                                    {flag.name}
                                </span>
                            )
                        })
                    }
                    let tr = <tr key={kontakt.id} id={kontakt.id} onClick={this.rowClickHandler}>
                            {Object.keys(TableConfig).map(key => {
                                if(key === 'flags') return '';
                                return <td key={kontakt.id+'-'+key}>{kontakt[key]}</td>
                            })}
                            <td key={kontakt.id+'-flags'}>{flags}</td>
                        </tr>
                    return tr;
                    })
            } else {
                tableRows = <tr key="no-data"><td colSpan={4}><h3>KEINE DATEN GEFUNDEN</h3></td></tr>
            } 
        }
        
        return (<>
            <EditModal ref={this.modal} toast={this.toast}/>
            <div className='row' style={this.state.boxStyle}>
                <div className='col g-0'>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                {Object.keys(TableConfig).map((o, index) => {
                                    let el = TableConfig[o];
                                    let orderBy = el.defaultOrder ? 'asc' : false;
                                    let canOrder = el.canOrder ? this.handlOrderBy : false;
                                    let type = el.type !== undefined ? el.type : false;
                                    return (
                                        <th key={el.label+'-'+index}>
                                            <FilterForm placeholder={el.label} handler={this.setSearchParams} order={canOrder} isDefaultOrder={orderBy} type={type} table={el.table}/>
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {tableRows}
                        </tbody>
                    </Table>
                </div>
            </div>
            <Pagination pagination={this.state.pagination} changePage={this.changePage}/>
        </>);
    }
}
 
export default Liste;
import axios from 'axios';
import React, { Component } from 'react';
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';

class Notices extends Component {
    constructor(props) {
        super(props);

        this.noticeLabelStyle = {
            width: '100%',
            backgroundColor: 'lightgrey',
            padding: '20px 10px',
            borderRadius: '5px',
            opacity: '0.75',
            overflowY: "scroll",
        }

        this.noticeInput = React.createRef();

        this.inputChangeHandler = this.inputChangeHandler.bind(this)
        this.submit = this.submit.bind(this)
        this.getNotices = this.getNotices.bind(this)
        this.deleteNotice = this.deleteNotice.bind(this)
        this.editNotice = this.editNotice.bind(this)
    }
    state = { 
        id: 0,
        rowId: 0,
        notices: [],
        note: '',
        show: false,
        submitDisabled: true,
        buttonText: 'Hinzufügen'
    }

    componentDidMount(){
        if(this.props.kontaktId && this.props.kontakId !== ''){
            this.getNotices();
        }
    }

    getNotices(){
        this.setState({id: this.props.kontaktId, show: true}, () => {
            global.axiosHelper.read(`/notices/${this.state.id}`)
                .then(res => {
                    // console.log(res)
                    this.setState({notices: res.data.notices})
                }).catch(err => console.log(err))
        })
    }

    componentDidUpdate(props){
        if(this.state.rowId !== 0){
            this.noticeInput.current.focus()
        }
    }
    
    componentWillUnmount(){
        this.setState({id: 0, show: false})
    }

    inputChangeHandler(e){
        if(e.target.value){
            this.setState({note: e.target.value, submitDisabled: false})
        }
    }

    submit(e){
        e.preventDefault()
        if(this.state.rowId === 0){
            global.axiosHelper.create(`/notices/`, {kontakte_id: this.state.id, notice: this.state.note})
            .then(res => {
                // console.log(res)
                e.target.reset();
                this.setState({note: '', submitDisabled: true})
                this.getNotices()
            }).catch(err => console.log(err))
        }else {
            global.axiosHelper.update(`/notices/${this.state.rowId}`, this.state.note)
            .then(res => {
                // console.log(res)
                e.target.reset();
                this.setState({note: '', buttonText: 'Hinzufügen', rowId: 0, submitDisabled: true})
                this.getNotices()
            }).catch(err => console.log(err))
        }
    }

    async deleteNotice(e){
        e.preventDefault()
        const removeId = e.target.dataset.remove;
        await global.axiosHelper.delete(`/notices/${removeId}`)
            .then(res => this.getNotices())
            .catch(err => console.log(err))
    }

    editNotice(e){
        let text = e.target.parentNode.outerText.substr(19)
        this.setState({note: text, buttonText: 'Ändern', rowId: e.target.dataset.target, submitDisabled: false}, () => {
            this.noticeInput.current.focus()
        })
    }

    render() { 
        let htmlCode = <div className='d-none'></div>
        if(this.state.show){
            htmlCode = <Row id="noticeBox">
                <Col>
                    <h5 className='formTitle'>Notizen</h5>
                    <Form id="notices" onSubmit={this.submit}>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Input id='note' name='note' type="text" onChange={this.inputChangeHandler} placeholder="neue Notiz" value={this.state.note} ref={this.noticeInput}/>
                                    <Input id='rowId' name='rowid' type="hidden" onChange={this.inputChangeHandler} value={this.state.rowId}/>
                                </FormGroup>
                            </Col>
                            <Col md={'auto'}>
                                <Button disabled={this.state.submitDisabled}>{this.state.buttonText}</Button>
                            </Col>
                        </Row>
                    </Form>
                    <Row>
                        <Col>
                            <label className='noticeLabel' style={this.noticeLabelStyle}>
                                {this.state.notices.map((notice, index) => {
                                    return <p key={index} className="note"><i data-target={notice.rowid} onClick={this.editNotice} className="bi bi-pencil-square"></i><i data-remove={notice.rowid} onClick={this.deleteNotice} className="bi bi-trash-fill"></i><span style={{fontWeight: 500}}>{new Date(notice.createdAt).toLocaleString('de-DE', {dateStyle: 'medium',timeStyle: 'short'})}:</span> {notice.notice}</p>
                                })}
                            </label>
                        </Col>
                    </Row>
                </Col>
            </Row>

        }
        return htmlCode;
    }
}
 
export default Notices;

export function assignFlags(id, newFlags, oldFlags = []){

    let updateNotices = (flags, method) => {
        //notice anlegen
        let note = ''
        console.log('flags', flags);

        if(method === "delete"){
            note = flags.length >1 ? 'Flags' : 'Flag';
            note += flags.map(flag => {
                return ` ${flag.name}`
            })
            note += ' entfernt.'
        }else if (method === "add"){
            note = flags.length >1 ? 'Flags' : 'Flag';
            note += flags.map(flag => {
                return ` ${flag.label}`
            })
            note += ' hinzugefügt.'
        }

        global.axiosHelper.create(`/notices/`, {kontakte_id: parseInt(id), notice: note})
        .then(res => {
            console.log(res)
            // this.getNotices()
        }).catch(err => console.log(err))
    }

    return new Promise(async (resolve, reject) => {
        //flage entfernen
        let deleteArray = []
        let deleteNote = []
        oldFlags.map(e => e.id).forEach((id, i) => {
            if(!newFlags.map(e => e.value).includes(id)){ 
                deleteArray.push(id)
                deleteNote.push(oldFlags[i])
            }
        })
        console.log('delete', deleteArray)

        if(deleteArray.length > 0){
            global.axiosHelper.delete(`/kontakte_flags/`,{flags_id: deleteArray.toString(), kontakte_id: parseInt(id)})
            .then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
                reject(err)
            })

            updateNotices(deleteNote, 'delete')
        }


        //flag Hinzufügen
        let addArray  = []
        let addNote = []
        newFlags.map(e => e.value).forEach((id, i) => {
            if(!oldFlags.map(e => e.id).includes(id)) {
                addArray.push(id)
                addNote.push(newFlags[i])
            }
        })
        console.log('add', addArray)

        if(addArray.length > 0){
            await global.axiosHelper.create(`/kontakte_flags`, {kontakte_id: parseInt(id), flags_id: addArray})
            .then(res => {
                console.log(res)
            }).catch( err => {
                console.log(err)
                reject(err)
            })

            updateNotices(addNote, 'add')
        }

        resolve(true)
    });
}

/**
 * Get Flag by Name
 * 
 * @param {*} flagName 
 * @returns flag {id, name, colour}
 */
export function getFlagIdByName(flagName) {
    return new Promise( async (resolve, reject)=> {
        global.axiosHelper.read('/flags', {name: flagName}).then( res => {
            resolve(res.data.flags[0])
        }).catch(err => {
            reject(err)
        })

    })
}
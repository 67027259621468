import React, { Component, createRef } from 'react';
import './App.scss';
import { Navigate } from "react-router-dom";
import Header from './Header';
import EmailListe from './comp/EmailListe';
import InfoToast from './comp/InfoToast';
import Loading from './views/Loading';

class App extends Component{
  constructor(props) {
    super(props);
    this.headerMenu = createRef();
  }
  state = {
    schemas: {},
    loggedIn: undefined,

  }

  componentDidMount(){
    this.setState({loggedIn: undefined}, () => {
      this.getSchema();
    })
  }

  async getSchema(){
    await global.axiosHelper.read(`/schemas`).then(res => {
      this.setState({schemas: res.data, loggedIn: true}, () => {
        global.schemas = this.state.schemas
        this.headerMenu.current.setState({showLogout: true})
      })
    }).catch(err => {
      // console.log('err', err);
      if(err.res.status === 403){
        this.setState({loggedIn: false})
        this.headerMenu.current.setState({showLogout: false})
      }
    });
    
  }

  render() {
    let login = <Navigate to="/login" replace={true} />
    let html = <>
        <EmailListe />
        <InfoToast ref={global.toast} timer="3500"/>
      </>
    if(this.state.loggedIn === undefined) return <><Loading/></>
    return (<>
      <Header ref={this.headerMenu} />
      { this.state.loggedIn ? html : login}
    </>
    );
  }
}

export default App;
import { Component } from "react";
import {Collapse, Nav, Navbar, NavbarBrand, NavbarText} from "reactstrap";
import HeaderMenu from "./HeaderMenu";

class Header extends Component {
  state = {
    showLogout: false,
  }

  render() {
    return(
      <header>
        <Navbar
          color="holl" expand="md" light>
          <NavbarBrand href="/">
            Holl Stahlbau GmBH - Vertriebstool
          </NavbarBrand>
          <Collapse isOpen={true} >
            <Nav className="me-auto" navbar={true}>
              {this.state.showLogout ? <HeaderMenu /> : '' }
            </Nav>
          </Collapse>
          <NavbarText>
            <span style={{fontSize: "0.75em"}}>&copy; <a style={{textDecoration: "none"}} href="https://sobek-innovations.de" target={"_blank"} rel="noreferrer">sobek-innovations.de</a></span>
          </NavbarText>
        </Navbar>
      </header>
    );
  }
}

export default Header;